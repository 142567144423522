import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pagePaletteHubble12: allPagesCompilationYaml(
      filter: { code: { eq: "paletteHubble12" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "paletteHubble22"
            "paletteHubblePhotoshop"
            "galerieNebuleusesPaletteHubble"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: { in: ["astrophotographieCCDNGC2237LaNebuleuseDeLaRosette"] }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "astrophotographieCCDImageavecLeFiltreSoufreSII"
            "astrophotographieCCDImageavecLeFiltreOxygeneOIII"
            "astrophotographieCCDImageavecLeFiltreHa"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageTresPetit
    }
  }
`
export default function PaletteHubble12() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pagePaletteHubble12")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageTresPetit"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          Sur internet et dans ma galerie, vous trouverez de nombreuses images
          réalisées en palette dite « Hubble » (ou palette « HST » pour « Hubble
          Space Telescope »). Ces images ont la particularité d’être très
          colorées. Mais ces couleurs sont assez étranges. Pourquoi ? Comment
          sont-elles réalisées ? Ces images ont elle un sens scientifique ?
          Explications.
        </p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDNGC2237LaNebuleuseDeLaRosette"
          )}
          langue={page.langue}
        />
      </Section>
      <Section titre="Les filtres">
        <p>
          Les nébuleuses sont essentiellement constituées d’hydrogène. D’autres
          sont aussi constituées de soufre, d’oxygène… Lorsqu’un gaz est{" "}
          <strong>chauffé</strong>, il émet{" "}
          <strong>une lumière d’une couleur bien précise</strong>. À l’aide d’un
          filtre adéquat, il est donc possible de réaliser des clichés des
          différents gaz constituant la nébuleuse. En astrophotographie, 3
          filtres sont couramment utilisés :
        </p>
        <ul>
          <li>Le filtre Hα permet d’isoler l’hydrogène</li>
          <li>Le filtre [OIII] permet d’isoler l’oxygène</li>
          <li>Le filtre [SII] permet d’isoler le soufre.</li>
        </ul>
      </Section>
      <Section titre="Un exemple">
        <p>
          Reprenons l’image de l’introduction. Voici le résultat de
          l’acquisition « brute », au travers des 3 filtres [SII], Hα, [OIII].{" "}
          <strong>Chaque filtre</strong> met en évidence{" "}
          <strong>les compositions</strong> des nuages de gaz de la nébuleuse.
          Les images ci-dessous sont en noir et blanc : elles ont été réalisées
          avec une caméra monochrome.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreSoufreSII"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreHa"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "astrophotographieCCDImageavecLeFiltreOxygeneOIII"
            )}
            langue={page.langue}
          />
        </div>
        <p>
          À gauche : l’image réalisée avec un filtre [SII] (pour le soufre), au
          milieu avec un filtre Hα (pour l’hydrogène), et à droite avec un
          filtre [OIII] (pour l’oxygène)
        </p>
      </Section>
      <Section titre="Une image en fausses couleurs">
        <p>
          La palette Hubble a été développée par la NASA afin de{" "}
          <strong>différencier</strong> au mieux les différents gaz présents
          dans les nébuleuses. Pour cela un montage « SHO » est réalisé :
        </p>
        <ul>
          <li>Le soufre (S) est représenté en rouge.</li>
          <li>L’hydrogène (H) est représenté en vert.</li>
          <li>L’oxygène (O) est représenté en bleu.</li>
        </ul>
        <p>
          L’image est dite en « <strong>fausses couleurs</strong> » car
          l’hydrogène excité émet majoritairement du <strong>rouge</strong>, et
          non du <strong>vert</strong>. Le résultat de cette inversion de
          couleur est une image dotée de couleurs cuivrées.
        </p>
      </Section>
      <Section titre="Les limites">
        <p>
          Malheureusement, <strong>il n’est pas possible</strong> d’utiliser
          cette technique <strong>sur toutes les nébuleuses</strong> : toutes ne
          contiennent pas du soufre et de l’oxygène. D’autres ne contiennent pas
          d’hydrogène du tout, comme les nébuleuses par réflexion : ce sont des
          nuages de poussières qui réfléchissent la lumière d’une étoile située
          en arrière-plan.
        </p>
      </Section>
    </LayoutTutorielFR>
  )
}
